// mobile version
$mobile-nbcard-padding: 13px;
$mobile-table-default-margin: 10px;

// desktop version
$table-column-margin: 10px;

$font-color: nb-theme(text-basic-color);

// Lumosa colors
// Corporate
$lumosa-color-1: #124734;
$lumosa-color-2: #3DAE2B;
$lumosa-color-3: #FFFFFF;
$lumosa-color-4: #E5E1E6;
$lumosa-color-5: #76777A;
$lumosa-color-6: #2A2A2A;

// Signal
$lumosa-color-signal-1: #5BB7E6;
$lumosa-color-signal-2: #006B90;
$lumosa-color-signal-3: #3DAE2B;
$lumosa-color-signal-4: #BAD900;
$lumosa-color-signal-5: #FFA600;
$lumosa-color-signal-6: #E9323F;

// Signal dark
$lumosa-color-signal-1-dark: #2189AE;
$lumosa-color-signal-2-dark: #004256;
$lumosa-color-signal-3-dark: #124734;
$lumosa-color-signal-4-dark: #80C313;
$lumosa-color-signal-5-dark: #F27E16;
$lumosa-color-signal-6-dark: #B9293B;

// Non-official colors,
// Based on the official green
$lumosa-green-000: #edffeb;
$lumosa-green-100: #b8f5b3;
$lumosa-green-200: #9af192;
$lumosa-green-300: #68da5e;
$lumosa-green-400: #56c74c;
$lumosa-green-500: $lumosa-color-2;
$lumosa-green-600: #139015;
$lumosa-green-700: #107e1d;
$lumosa-green-800: #0e622b;
$lumosa-green-900: #0b4639;


// Gray colors
$lightest-gray: #f9f9f9;
$light-gray: #ebebeb;
$light-gray-hover: #cbcbcb;
$light-gray-active: #a1a1a1;

$mid-gray: #a7a7a7;

$dark-gray: #808080;
$darker-gray: #666;