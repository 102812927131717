@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  .mat-menu-item {
    nb-icon {
      margin-right: 16px;
    }
  }

  nb-card-body.align-right {
    display: flex;
    flex-direction: row-reverse;
  }

  nb-card-body {
    position: relative;
    overflow: inherit;
  }

  nb-toast {
    .message {
      white-space: pre-line;
    }
  }

  nb-tooltip {
    white-space: pre-line;
  }
}