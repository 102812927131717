@import './variables.scss';

.mat-datepicker-content {
    background: white;
    display: block;
    border-radius: 4px;
    box-shadow: 0 2px 4px -1pxrgba(0, 0, 0, .2), 0 4px 5px 0rgba(0, 0, 0, .14), 0 1px 10px 0rgba(0, 0, 0, .12);
    outline: none;
}

.mat-ripple,
.mat-button-wrapper,
.mat-calendar-controls,
.mat-icon,
.mat-focus-indicator {
    outline: none !important;
}

.mat-calendar-header {
    outline: none;
    padding: 8px 8px 0;
}

.mat-calendar-table-header,
.mat-calendar-body-label {
    color: rgba(0, 0, 0, .54);
    outline: none;
}

input[matinput]:not(.mat-input-element) {
    background-color: #f7f9fc;
    border-color: #e4e9f2;
    color: #222b45;
    font-size: 0.9375rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 0.4375rem 1rem;
    border-style: solid;
    border-width: 1px;
    font-family: Open Sans, sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    font-weight: normal;

    &[fullwidth] {
        width: 100%;
    }

    &:focus {
        border-color: $lumosa-color-2;
        border-width: 1px;
    }

    &:focus-visible {
        outline: none;
    }

}

.mat-calendar-body-today {
    background-color: $lumosa-green-100;
}

.mat-calendar-body-selected {
    background-color: $lumosa-color-2;
}

.mat-datepicker-toggle {
    position: fixed;
    margin: 0 -40px;

    .mat-icon-button {
        background: transparent;
    }
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
}



$calendar-padding: 8px !default;
$calendar-header-divider-width: 1px !default;
$calendar-controls-vertical-padding: 5%;
$calendar-controls-side-margin: calc(33% / 7 - 16px);

$calendar-arrow-size: 5px !default;
$calendar-arrow-disabled-opacity: 0.5 !default;


$calendar-prev-next-icon-border-width: 2px;
$calendar-prev-next-icon-margin: 15.5px;
$calendar-prev-icon-transform: translateX(2px) rotate(-45deg);
$calendar-next-icon-transform: translateX(-2px) rotate(45deg);

.mat-calendar {
    display: block;
}

.mat-calendar-header {
    padding: $calendar-padding $calendar-padding 0 $calendar-padding;
}

.mat-calendar-content {
    padding: 0 $calendar-padding $calendar-padding $calendar-padding;
    outline: none;
}

.mat-calendar-controls {
    display: flex;
    margin: $calendar-controls-vertical-padding $calendar-controls-side-margin;

    .mat-icon-button:hover .mat-button-focus-overlay {
        opacity: 0.04;
    }
}

.mat-calendar-spacer {
    flex: 1 1 auto;
}

.mat-calendar-period-button {
    min-width: 0;
}

.mat-calendar-arrow {
    display: inline-block;
    width: $calendar-arrow-size * 2;
    height: $calendar-arrow-size;
    margin: 0 0 0 $calendar-arrow-size;
    vertical-align: middle;

    &.mat-calendar-invert {
        transform: rotate(180deg);
    }

    [dir='rtl'] & {
        margin: 0 $calendar-arrow-size 0 0;
    }

}

.mat-calendar-previous-button,
.mat-calendar-next-button {
    position: relative;

    &::after {
        content: '';
        margin: $calendar-prev-next-icon-margin;
        border: 0 solid currentColor;
        border-top-width: $calendar-prev-next-icon-border-width;
    }

    [dir='rtl'] & {
        transform: rotate(180deg);
    }
}

.mat-calendar-previous-button::after {
    border-left-width: $calendar-prev-next-icon-border-width;
    transform: $calendar-prev-icon-transform;
}

.mat-calendar-next-button::after {
    border-right-width: $calendar-prev-next-icon-border-width;
    transform: $calendar-next-icon-transform;
}

.mat-calendar-table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

.mat-calendar-table-header th {
    text-align: center;
    padding: 0 0 $calendar-padding 0;
}

.mat-calendar-table-header-divider {
    position: relative;
    height: $calendar-header-divider-width;

    // We use an absolutely positioned pseudo-element as the divider line for the table header so we
    // can extend it all the way to the edge of the calendar.
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -$calendar-padding;
        right: -$calendar-padding;
        height: $calendar-header-divider-width;
    }
}

// For the calendar element, default inset/offset values are necessary to ensure that
// the focus indicator is sufficiently contrastive and renders appropriately.
.mat-calendar-body-cell-content::before {
    $border-width: 1px;
    $offset: calc(#{$border-width} + 3px);
    margin: calc(#{$offset} * -1);
}

// For calendar cells, render the focus indicator when the parent cell is
// focused.
.mat-calendar-body-cell:focus .mat-focus-indicator::before {
    content: '';
}

.mat-datepicker-content .time-container {
    display: flex;
    position: relative;
    padding-top: 5px;
    justify-content: center;
}

.mat-calendar-body-label {
    height: 0;
    line-height: 0;
    text-align: left;
    padding-left: 4.7142857143%;
    padding-right: 4.7142857143%;
}

.mdc-menu-surface {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: nb-theme(background-basic-color-3) !important;
    color: nb-theme(text-basic-color) !important;
}

::ng-deep .mdc-notched-outline > * {
    border: 1px solid red !important;
  }