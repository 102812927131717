@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';

@import './variables.scss';
@import './mat-styles.scss';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

.mat-menu-content:not(:empty) {
  border: 1px solid $mid-gray;
  padding: 0px !important;

  nb-icon {
    color: $lumosa-color-1;
  }

  a {
    text-decoration: none;

    &:not(:last-child) {
      button {
        border-bottom: 1px solid $light-gray-hover;
      }
    }
  }

  button {

    &:not(:last-child) {
      border-bottom: 1px solid $light-gray-hover;
    }

    &:hover {
      transition: 0.1s;
      background-color: $light-gray-hover;
    }

    &:active {
      transition: 0.1s;
      background-color: $light-gray-active;
    }

    &:focus {
      transition: 0.1s;
      outline: none;
    }
  }

}

textarea:read-only {
  background-color: nb-theme(background-basic-color-1) !important;
  color: $font-color !important;
  cursor: default;
}

input {
  &.ng-invalid {
    border-color: $lumosa-color-signal-6 !important;
  }

  &[type="text"]:disabled,
  &[type="text"]:read-only {
    background-color: nb-theme(background-basic-color-1) !important;
    color: $font-color !important;
    cursor: default;
  }
}

button.select-button:disabled {
  background-color: nb-theme(background-basic-color-1) !important;
  color: $font-color !important;
}

nb-option {
  &:nth-child(even) {
    background-color: rgba(#3DAE2B, .15);
  }
}

nb-card.shadow {
  -webkit-box-shadow: -2px 8px 15px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -2px 8px 15px -10px rgba(0, 0, 0, 0.5);
  box-shadow: -2px 8px 15px -10px rgba(0, 0, 0, 0.5);
}

nb-select {
  &.ng-invalid {
    button {
      border-color: $lumosa-color-signal-6 !important;
      opacity: 1;
    }
  }
}

nb-icon {
  font-size: var(--icon-font-size) !important;
}